import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row, Table, Card, CardBody, Label } from 'reactstrap';

import $filter from '../../../../common/utils/$filter';
import ErrorDialog from '../../../../components/Common/ErrorDialog';
import InputText from '../../../../components/Common/InputText';
import LegalIdInput from '../../../../components/Common/LegalIdInput';
import Loader from '../../../../components/Common/Loader';
import SelectOrganizationModal from '../../../../components/Common/SelectOrganizationModal';
import StatusDomain from '../../../../domains/StatusDomain';
import { useCreateOrUpdateUserForm } from '../hooks';

const Component = ({
  t,
  user,
  isAdm,
  isEdit,
  onClose,
  onCreated,
  onUpdated,
  onShowMessage,
}) => {
  const {
    error,
    isLoading,
    isOpenOrganizationsModal,
    form,
    handleFormSubmit,
    handleCpfChange,
    handleEmailChange,
    handleStatusChange,
    handleAddOrganization,
    handleOrganizationModalOpen,
    handleSendPwdMail,
    handleErrorClose,
    removeOrganization,
  } = useCreateOrUpdateUserForm({
    t,
    isEdit,
    isAdm,
    user,
    showSuccess: onShowMessage,
    updateUsersListToShowCreatedUser: onCreated,
    updateUsersListToShowUpdatedUser: onUpdated,
    closeModal: onClose,
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <Scrollbars style={{ height: window.innerHeight - 80 }}>
        {error && (
          <ErrorDialog title={error.title} onClose={handleErrorClose} code={1}>
            <p>{error.detail} </p>
          </ErrorDialog>
        )}

        <div className='modal-header'>
          {!isEdit ? (
            <h5 className='modal-title mt-0'>{t('Register User')}</h5>
          ) : (
            <h5 className='modal-title mt-0'>
              <p className='card-title-desc mb-1'>
                <small> {t('User')}</small>
              </p>
              <p className='mb-0'>{form.name}</p>
              <p className='mb-0'>
                <small>{$filter(form.cpf, 'CPF_CNPJ')}</small>
              </p>
            </h5>
          )}

          <button
            type='button'
            onClick={onClose}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body'>
          {!isEdit && (
            <Row>
              <Col lg={12} className='mb-3'>
                <LegalIdInput
                  id='legalId'
                  required={true}
                  autoComplete='off'
                  label={t('CPF')}
                  onChange={handleCpfChange}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12} className='mb-3'>
              <InputText
                id='userName'
                label={t('Email')}
                required={true}
                value={form.email}
                onChange={handleEmailChange}
              />
            </Col>
          </Row>

          {isEdit && (
            <Row className='mb-3'>
              <Col lg={12}>
                <Link to='#!' onClick={handleSendPwdMail}>
                  {t('Send password recovery e-mail')}
                </Link>
              </Col>
            </Row>
          )}

          <Row>
            {isAdm && (
              <>
                <Label className='form-label'>*Status</Label>
                <Col className='d-flex mb-2' style={{ gap: 8 }}>
                  <label
                    className={`${StatusDomain.getClassFromValue(
                      'A',
                    )} status-label`}
                  >
                    <input
                      type='radio'
                      name='status'
                      value='A'
                      defaultChecked={form.status === 'A'}
                      onChange={handleStatusChange}
                    />
                    {StatusDomain.getNameFromValue('A')}
                  </label>
                  <label
                    className={`${StatusDomain.getClassFromValue(
                      'I',
                    )} status-label`}
                  >
                    <input
                      type='radio'
                      name='status'
                      value='I'
                      defaultChecked={form.status === 'I'}
                      onChange={handleStatusChange}
                    />
                    {StatusDomain.getNameFromValue('I')}
                  </label>
                </Col>
              </>
            )}
          </Row>

          {isAdm && (
            <Row>
              <p className='card-title-desc mt-3 mb-0'>
                Organizações de acesso
              </p>
              <hr />

              <Col lg={12} className='mb-3'>
                <button
                  type='button'
                  className='btn w-100 btn-select'
                  onClick={handleOrganizationModalOpen}
                >
                  {t('Add Organization')}
                </button>
              </Col>
            </Row>
          )}

          {form.organizations.length > 0 && (
            <Row>
              <Col lg={12} className='mb-3'>
                <Card>
                  <CardBody>
                    <Table className='table table-striped mb-0'>
                      <tbody>
                        {(form.organizations || []).map((entity, key) => (
                          <tr key={entity['legalId']}>
                            <td>
                              <p
                                className='mb-0 d-flex align-items-center'
                                style={{ gap: 8 }}
                              >
                                {entity['name']}
                                <label
                                  className={`${StatusDomain.getClassFromValue(
                                    entity.status,
                                  )} status-label`}
                                >
                                  {StatusDomain.getNameFromValue(entity.status)}
                                </label>
                              </p>
                              <p className='mb-0'>
                                <small>
                                  {$filter(entity['legalId'], 'CPF_CNPJ')}
                                </small>
                              </p>
                            </td>

                            {isAdm && (
                              <td
                                className='col-md-1 text-center'
                                style={{ cursor: 'pointer' }}
                                onClick={() => removeOrganization(entity)}
                              >
                                <i className='fa fa-trash'></i>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Scrollbars>

      <div className='modal-footer  fixed-bottom'>
        <div className='text-end'>
          <button
            type='submit'
            className='btn btn-primary w-sm waves-effect waves-light'
          >
            {t('Save')}
          </button>
        </div>
      </div>

      {isLoading && <Loader loading={isLoading} />}

      {isOpenOrganizationsModal && (
        <SelectOrganizationModal
          isOpen={isOpenOrganizationsModal}
          callback={handleAddOrganization}
        />
      )}
    </form>
  );
};

export const CreateOrUpdateUserForm = withTranslation()(Component);
