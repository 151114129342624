import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory, withRouter, Link } from 'react-router-dom';
import { Alert, Row } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';

import Loader from '../../components/Common/Loader';
import { post, createNewSession } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import { getHome } from '../../routes/allRoutes';

const Login = (props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleValidSubmit = async (event, values) => {
    try {
      const { userName, secret } = values;

      const response = await post(
        url.POST_LOGIN,
        { userName, secret },
        {
          headers: { Authorization: '', 'x-a-oid': '' },
        },
        setLoading,
      );
      if (response['status'].toString() === '200') {
        await createNewSession(response['user']);
        window.location = getHome();
      }
    } catch (e) {
      console.error(e);
      props.onFailure({
        title: props.t('Invalid login'),
        detail: props.t('Invalid email or password'),
        status: 404,
      });
    }
  };

  document.body.classList.add('login_page');

  useEffect(() => {
    let sessionExpired = JSON.parse(localStorage.getItem('sessionExpired'));
    if (sessionExpired !== null && sessionExpired !== undefined) {
      localStorage.removeItem('sessionExpired');

      props.onFailure({
        title: props.t('Session expired'),
        status: '403',
        detail: props.t('Session has expired, please login again'),
      });
    }
  });

  const recoverPassword = () => {
    props.requestNewPwd();
  };

  return (
    <React.Fragment>
      {!window.chrome ? (
        <div className='mt-2 pt-3 text-md-center'>
          <Alert color='danger' className='text-center mb-4 mt-2'>
            No momento, o navegador homologado para nossa plataforma é o Google
            Chrome, e você parece estar utilizando um navegador diferente.
          </Alert>
        </div>
      ) : (
        ''
      )}
      <div id='v226'>
        <h3>{props.t('Access a Cash2Pay')}</h3>
      </div>

      <div className='mt-2 pt-3'>
        <AvForm className='form-horizontal' onValidSubmit={handleValidSubmit}>
          <Row className='mb-4'>
            <div>
              <AvField
                name='userName'
                label='Email'
                className='form-control'
                placeholder={props.t('Enter your email here')}
                type='email'
                required
                errorMessage={props.t('Invalid email')}
                style={{ height: 48 }}
              />
            </div>
          </Row>

          <Row>
            <div>
              <div className='float-end'>
                <Link to='#!' className='text-muted' onClick={recoverPassword}>
                  {props.t('I forgot my password')}
                </Link>
              </div>
              <AvField
                style={{ height: 48 }}
                name='secret'
                label={props.t('Secret')}
                type='password'
                errorMessage={props.t('Required field')}
                required
                placeholder={props.t('Enter your password here')}
              />
            </div>
          </Row>

          <Row>
            <div className='mt-4'>
              <button
                className='btn btn-primary w-100 waves-effect waves-light'
                type='submit'
                style={{ height: 42 }}
              >
                {props.t('Login')}
              </button>
            </div>
            <div className='mt-4 text-center'>
              <p className='mb-0'>
                Ao realizar o login você concorda com os{' '}
                <a
                  href='https://drive.google.com/file/d/1TUb_ePBbWGpcIXYP5qJbffky33a7H9nW/view?usp=sharing'
                  target='_blank'
                  rel='noreferrer'
                >
                  TERMOS DE USO
                </a>{' '}
                e{' '}
                <a
                  href='https://drive.google.com/file/d/1xibcdAJK9AomCS8UM7xP08EuJrnC7I-D/view?usp=sharing'
                  target='_blank'
                  rel='noreferrer'
                >
                  POLÍTICA DE PRIVACIDADE
                </a>
              </p>
            </div>
          </Row>
          <Row>
            <div className='mt-4 text-center'>
              <p className='mb-0'>
                <a href='/login' className='fw-medium text-primary'>
                  {props.t('Create a Cash2Pay account')}
                </a>
              </p>
            </div>
          </Row>
        </AvForm>
      </div>

      {loading ? <Loader loading={loading} /> : ' '}
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));
