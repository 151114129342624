import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';

import { USER_PROFILES } from '../../../domains/UserProfileDomain';
import { getSessionUserProfile } from '../../../helpers/api_helper';
import { CreateOrUpdateUserForm } from './components/CreateOrUpdateUserForm';

const Component = ({
  isEdit,
  user,
  isOpen,
  onClosed,
  onUserCreated,
  onUserUpdated,
  onShowMessage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const userProfile = getSessionUserProfile();

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={handleModalClose}
      onClosed={onClosed}
      modalClassName='drawer right-align'
      contentClassName='modal-content-scroll'
    >
      <CreateOrUpdateUserForm
        user={user}
        isAdm={userProfile === USER_PROFILES.ADM}
        isEdit={isEdit}
        onCreated={onUserCreated}
        onUpdated={onUserUpdated}
        onClose={handleModalClose}
        onShowMessage={onShowMessage}
      />
    </Modal>
  );
};

export const CreateOrUpdateUserModal = withTranslation()(Component);
