import { useState } from 'react';

import { isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';

import { authService, usersService } from '../../../../services/http';
import { showExceededPlanModal, showSuspensedPlanModal } from '../utils';

export const useCreateOrUpdateUserForm = ({
  t,
  isEdit,
  isAdm,
  user,
  showSuccess,
  updateUsersListToShowCreatedUser,
  updateUsersListToShowUpdatedUser,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isOpenOrganizationsModal, setIsOpenOrganizationsModal] =
    useState(false);

  const [form, setForm] = useState(() => ({
    cpf: user?.legalId || '',
    name: user?.name || '',
    status: user?.status || '',
    organizations: user?.organizations || [],
    email: user?.userName || '',
  }));

  const handleCpfChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      cpf: e.target.value.trim(),
    }));
  };

  const handleEmailChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      email: e.target.value.trim(),
    }));
  };

  const handleStatusChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      status: e.target.value,
    }));
  };

  const handleAddOrganization = (resultModal) => {
    setIsOpenOrganizationsModal(false);

    if (resultModal['event'] === 'SELECTED') {
      const exists = form.organizations.some((org) => {
        return org.legalId === resultModal['entity']['legalId'];
      });

      if (!exists) {
        setForm({
          ...form,
          organizations: [...form.organizations, resultModal['entity']],
        });
      }
    }
  };

  const handleOrganizationModalOpen = () => {
    setIsOpenOrganizationsModal(true);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const handleSendPwdMail = async () => {
    if (isLoading) return;

    if (!isValidEmail(form.email)) {
      setError({ detail: 'Preencha o campo com um Email válido' });
      return;
    }

    setIsLoading(true);

    try {
      await authService.recoveryPassword(form.email);

      showSuccess({ detail: t('Password recovery e-mail sent') });
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  const removeOrganization = (entity) => {
    setForm((prevState) => ({
      ...prevState,
      organizations: prevState.organizations.filter(
        (org) => org.legalId !== entity['legalId'],
      ),
    }));
  };

  const createUser = async (userToCreate) => {
    setIsLoading(true);

    try {
      const response = await usersService.create(userToCreate);

      updateUsersListToShowCreatedUser({
        ...userToCreate,
        ...response['user'],
      });

      showSuccess({
        detail: t('User successfully registered'),
      });

      closeModal();
    } catch (e) {
      setIsLoading(false);

      if (e.status === '200' || e?.violations?.length === 0) return;

      const isPlanEceeded = e.violations.some(
        ({ property }) => property === 'accessPlan.maxUsers',
      );
      const isPlanSuspensed = e.violations.some(
        ({ property }) => property === 'accessPlan.isPlanOnline',
      );

      if (isPlanEceeded) {
        showExceededPlanModal();
      } else if (isPlanSuspensed) {
        showSuspensedPlanModal();
      } else {
        setError(e);
      }
    }
  };

  const updateUser = async (userToUpdate) => {
    setIsLoading(true);

    try {
      const response = await usersService.update(
        userToUpdate.legalId,
        userToUpdate,
      );

      updateUsersListToShowUpdatedUser({
        ...userToUpdate,
        ...response['user'],
      });

      showSuccess({
        detail: t('User successfully changed'),
      });

      closeModal();
    } catch (e) {
      setIsLoading(false);

      if (e.status === '200' || e?.violations?.length === 0) return;

      const isPlanEceeded = e.violations.some(
        ({ property }) => property === 'accessPlan.maxUsers',
      );
      const isPlanSuspensed = e.violations.some(
        ({ property }) => property === 'accessPlan.isPlanOnline',
      );

      if (isPlanEceeded) {
        showExceededPlanModal();
      } else if (isPlanSuspensed) {
        showSuspensedPlanModal();
      } else {
        setError(e);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    if (!isValidEmail(form.email)) {
      setError({ detail: 'Preencha o campo com um Email válido' });
      return;
    }
    if (!isEdit && !isValidCPF(form.cpf)) {
      setError({ detail: 'Preencha o campo com um CPF válido' });
      return;
    }
    if (isAdm && !['A', 'I'].includes(form.status)) {
      setError({ detail: 'Selecione um status' });
      return;
    }

    setError(null);

    const user = {
      legalId: form.cpf,
      name: form.name,
      userName: form.email,
      status: form.status,
      organizations: form.organizations,
    };

    if (isEdit) {
      updateUser(user);
    } else {
      createUser(user);
    }
  };

  return {
    error,
    isLoading,
    isOpenOrganizationsModal,
    form,
    handleFormSubmit,
    handleEmailChange,
    handleCpfChange,
    handleStatusChange,
    handleAddOrganization,
    handleOrganizationModalOpen,
    handleSendPwdMail,
    handleErrorClose,
    removeOrganization,
  };
};
